<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button
            class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1"
            @click="back()"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">Confirmar negociação</h4>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="flex-wrap col-acao pt-0 mt-0 col-lg-5">
          <div v-bind:class="[largura < 500 ? 'card m-1' : 'card']">
            <div class="card-header px-3">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5>{{ offerInfo.product }}</h5>
                  <h6 class="text-body">Resumo da negociação</h6>
                </div>
              </div>
            </div>

            <div class="card-body mb-2 pb-2 pt-0" v-if="from !== 'propostasCdc'">
              <div class="row border-top border-info mx-0 px-1" v-if="offerInfo.inputValue">
                <hr class="my-2 border-info" style="">
                <div class="col-6 px-0" style="">
                  <p class="mb-0">Valor com desconto</p>
                </div>
                <div class="col-6 px-0 text-right" style="">
                  <h6 class="text-body mb-0 mt-1">{{ formatFloatToReal(parseFloat(offerInfo.value, 10)) }}</h6>
                </div>
              </div>
              <div class="row border-top border-info mx-0 px-1">
                <hr class="my-2 border-info">
                <div class="col-6 px-0">
                  <p class="mb-0">Número de parcelas</p>
                </div>
                <div class="col-6 px-0 text-right">
                  <h6 class="text-body mb-0 mt-1">{{ offerInfo.installmentsNumber > 1 ?
                    (parseInt(offerInfo.installmentsNumber)) : 'À vista' }}</h6>
                </div>
              </div>
              <div class="row border-info mx-0 border-top px-1" v-if="offerInfo.installmentsNumber > 1">
                <hr class="my-2 border-info">
                <div class="px-0 col-lg-3 col-3">
                  <p class="mb-0">Condições</p>
                </div>
                <div class="px-0 text-right col-9">
                  <h6 class="text-body mb-0 mt-1" v-if="this.from === 'propostasContencioso'">Entrada {{
                    formatFloatToReal(parseFloat(offerInfo.inputValue, 10)) }} + {{ offerInfo.installmentsNumber - 1 }}x
                    {{ formatFloatToReal(parseFloat(offerInfo.installmentValue, 10)) }}</h6>
                  <h6 class="text-body mb-0 mt-1" v-if="this.from === 'propostas'">{{ offerInfo.installmentsNumber }}x {{
                    formatFloatToReal(parseFloat(offerInfo.installmentValue, 10)) }}</h6>
                </div>
              </div>
              <div class="row border-info mx-0 border-top px-1" v-if="offerInfo.installmentsNumber <= 1">
                <hr class="my-2 border-info">
                <div class="px-0 col-lg-3 col-3">
                  <p class="mb-0">Condições</p>
                </div>
                <div class="px-0 text-right col-9">
                  <h6 class="text-body mb-0 mt-1">Pagamento à vista de {{
                    formatFloatToReal(parseFloat(offerInfo.installmentValue, 10)) }}</h6>
                </div>
              </div>
              <div class="row border-info mx-0 border-top border-bottom px-1">
                <hr class="my-2 border-info">
                <div class="col-6 px-0">
                  <p class="mb-0">Primeiro vencimento</p>
                </div>
                <div class="col-6 px-0 text-right">
                  <h6 class="text-body mb-0 mt-1">{{ formatData(offerInfo.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row border-info mx-0 px-1 border-bottom">
                <hr class="my-2 border-info">
                <div class="col-6 px-0">
                  <p class="mb-0">Forma de pagamento</p>
                </div>
                <div class="col-6 px-0 text-right">
                  <h6 class="text-body mb-0 mt-1">PIX / Boleto</h6>
                </div>
              </div>
              <div class="row mt-3 mb-4 px-2">
                <div class="col-lg-1 text-center px-1 col-1"><i class="fa fa-info-circle text-danger"></i></div>
                <div class="px-1 col-lg-11 col-11">
                  <p class="small" v-if="isPst"> Nesta modalidade, você escolhe realizar o parcelamento do saldo total da
                    fatura e dos saldos futuros (compras parceladas a vencer). É importante mencionar que durante este
                    parcelamento você não terá mais a possibilidade de utilizar o seu cartão. Após o fim do parcelamento,
                    você poderá solicitar um novo cartão e estará sujeito à análise de crédito. O IOF sobre operações
                    financeiras será cobrado na segunda parcela do acordo.</p>
                  <p class="small" v-if="!isPst && from === 'propostas'"> O parcelamento da fatura é efetivado mediante o
                    pagamento do valor exato da primeira parcela. As demais parcelas serão lançadas nas suas próximas
                    faturas.</p>
                  <p class="small" v-if="from === 'propostasContencioso'"> Este acordo compõe o saldo geral do seu cartão
                    de crédito (parcelas vencidas e a vencer). Durante a vigência do acordo você não terá mais a
                    possibilidade de utilizar o seu cartão. Após a quitação do acordo, você poderá solicitar um novo
                    cartão Porto sujeito à análise de crédito.
                  </p>
                  <p class="small"> Após o pagamento da primeira parcela, o débito junto ao Serasa será baixado em até 5 (cinco) dias úteis.  
                    Para fins de reporte das informações do SCR junto ao Banco Central, esclarecemos que a atualização poderá levar até 60 (sessenta) dias.
                  </p>
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-3">
                <div class="text-center col-md-12"><button class="btn btn-link float- mr-5 mb-4"
                    :disabled="loadSetAgreement" @click="back()">
                    <i class="fa fa-chevron-left fa-fw pull-left mt-1"></i> Voltar
                  </button>
                  <button class="btn mb-4 btn-primary" :disabled="loadSetAgreement"
                    @click="fecharAcordo()">Confirmar</button>
                </div>
              </div>
              <div class="container" v-if="loadSetAgreement">
                <ProgressLoading v-bind:loading="loadSetAgreement" />
              </div>
            </div>


            <div class="card-body mb-2 pb-2 pt-0" v-if="from === 'propostasCdc'">
              <div class="row border-top border-info mx-0 py-2 px-1">
                <hr class="my-2 border-info" style="">
                <div class="col-6 px-0" style="">
                  <p class="mb-0">Valor negociado</p>
                </div>
                <div class="col-6 px-0 text-right" style="">
                  <h6 class="text-body mb-0 mt-1">{{ formatFloatToReal(parseFloat(offerInfo.value, 10)) }}</h6>
                </div>
              </div>
              <div class="row border-top border-info mx-0 py-2 px-1">
                <hr class="my-2 border-info" style="">
                <div class="px-0 col-8" style="">
                  <p class="mb-0">Nº de parcelas negociadas</p>
                </div>
                <div class="px-0 text-right col-4" style="">
                  <h6 class="text-body mb-0 mt-1">{{ agreementInfo.installments.length }}</h6>
                </div>
              </div>
              <div class="row py-2 border-top border-info mx-0 px-1">
                <hr class="my-2 border-info">
                <div class="px-0 col-lg-12 col-12">
                  <p class="mb-0">Parcelas selecionadas</p>
                </div>
              </div>
              <div :style="agreementInfo.installments.length > 5
                  ? 'height: 11rem; overflow-y: auto;'
                  : ''
                ">

                <div class="row py-2 border-info mx-0 px-1" v-for="(value, key, index) in agreementInfo.installments"
                  :key="`${key}-${index}`" style="height: 20px;">
                  <hr class="my-2 border-info">
                  <div class="px-0 col-3 col-md-3">
                    <p class="mb-0">{{ value.installmentNumber }}</p>
                  </div>
                  <div class="px-0 text-right col-md-4 col-4">
                    <p class="mb-0">{{ formatData(value.dueDate, "YYYY-MM-DD", "L") }}</p>
                  </div>
                  <div class="px-0 text-right col-md-5 col-5">
                    <p class="mb-0">{{ formatFloatToReal(parseFloat(value.originalValue, 10)) }}</p>
                  </div>
                </div>
              </div>
              <div class="row py-2 border-info mx-0 border-top border-bottom px-1" style="margin-top: 15px;">
                <hr class="my-2 border-info">
                <div class="col-6 px-0">
                  <p class="mb-0">Vencimento</p>
                </div>
                <div class="col-6 px-0 text-right">
                  <h6 class="text-body mb-0 mt-1">{{ formatData(offerInfo.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row py-2 border-info mx-0 px-1 border-bottom">
                <hr class="my-2 border-info">
                <div class="col-6 px-0">
                  <p class="mb-0">Forma de pagamento</p>
                </div>
                <div class="col-6 px-0 text-right">
                  <h6 class="text-body mb-0 mt-1">PIX / Boleto</h6>
                </div>
              </div>

              <div class="row d-flex justify-content-center mt-3">
                <div class="text-center col-md-12"><button class="btn btn-link float- mr-5 mb-4"
                    :disabled="loadSetAgreement" @click="back()">
                    <i class="fa fa-chevron-left fa-fw pull-left mt-1"></i> Voltar
                  </button>
                  <button class="btn mb-4 btn-primary" :disabled="loadSetAgreement"
                    @click="fecharAcordo()">Confirmar</button>
                </div>
              </div>
              <div class="container" v-if="loadSetAgreement">
                <ProgressLoading v-bind:loading="loadSetAgreement" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData, formatDocument, truncateString } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import { initializeGlobals } from '@/global';

export default {
    name: "resumo-acordo",
     components: {
       ProgressLoading
    },
    data () {
        return {
          containParcel:false,
          loadSetAgreement:false,
          parcelado:false,
          offerInfo: [],
          isPst:false,
          from:null,
          isWithEntryValue: false,
          pageLocation: null,
          userDataStorage: {},
          userAdm: {},
          ipUser: null

        }
    },

    async created() {
        this.updateCustomData();
        this.ipUser = localStorage.getItem('ipUser');
        localStorage.setItem("currentPageName", this.$options.name);
        this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
        this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
        this.protocol = window.location.protocol;
        this.host = window.location.host;
        this.path = window.location.pathname;
        this.pageLocation = this.protocol + '//' + this.host + this.path;

        this.largura = window.screen.width;
        this.offerInfo = this.$route.params.offerInfo;
        this.agreementInfo = this.$route.params.agreementInfo;
        console.log('this.agreementInfo: ' + JSON.stringify(this.agreementInfo))
        this.sumTotalInstallmentsCdc = this.$route.params.sumTotalInstallmentsCdc;
        this.totalInstallmentsCdcWithFees = this.$route.params.totalInstallmentsCdcWithFees;

        this.from = this.$route.params.from;
        this.isWithEntryValue = this.$route.params.isWithEntryValue;

        this.isPst = this.$route.params.isPst;
        this.loading = false;
    },
    methods: {
        formatValue,
        formatData,
        formatDocument,
        truncateString,

        ...mapActions("meusContratos", ["ActionGetAgreementPortoSeguro"]),

        async fecharAcordo() {
          this.eventClickConfirmarGTM();

          this.loadSetAgreement = true;

          let paymentOptionCodeFinal = [];
          if(this.offerInfo.paymentOptionsCodeIds.length > 0) {
             paymentOptionCodeFinal = this.offerInfo.paymentOptionsCodeIds.filter(value => {
              return Object.values(value)[0].includes(this.offerInfo.installmentsNumber);
            })[0];
          }

          try {
            const response = await this.ActionGetAgreementPortoSeguro({
              clientCode: this.offerInfo.clientCode,
              credor: this.offerInfo.credor,
              paymentOptionCode: this.offerInfo.paymentOptionsCodeIds.length > 0 ? parseInt(Object.keys(paymentOptionCodeFinal)[0]) : this.offerInfo.paymentOptionCode,
              plan: this.offerInfo.plan,
              invoiceOption: this.offerInfo.invoiceOption,
              contract: this.offerInfo.contract,
              tag: localStorage.getItem('tag'),
              phoneNumber: this.user.PhoneNumber != null ? this.user.PhoneNumber : this.user.MobileNumber,
              agreementType: this.offerInfo.agreementType,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS:navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
              hasEmail: this.user.Email !== null && this.user.Email !== "" ? true : false
            })

            this.flashMessage.show({
              timeout: 1000,
              status: "success",
              title: "Sucesso",
              blockClass: "custom-block-class",
              message: "Acordo fechado com sucesso",
            });
            this.eventCallbackSuccessAgreementGTM(response.installmentCode);
            this.verBoletos(response)
          } catch (error) {
            this.flashMessage.show({
              time:3000,
              status: 'error',
              position:'top right',
              title: 'Erro',
              message: 'Não foi possível fechar o acordo, tente novamente mais tarde!'
            });
            this.eventCallbackErrorAgreementGTM(error);
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
            this.updateCustomData();
            // this.$router.push({
            //   name: "dashboard"
            // });
          } finally {
            this.loadSetAgreement = false;
          }
        },

        async verBoletos(value) {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/meus-boletos', 'meus-boletos');
          this.updateCustomData();
          this.$router.push({
            name: "meusBoletos",
            params: {
              agreementInfo: {
                clientCode: this.offerInfo.clientCode,
                credor: this.offerInfo.credor,
                product: this.offerInfo.product,
                contract: this.offerInfo.contract,
                ...value,
              } ,
              fromProposals: true,
            }
          });
        },

        formatFloatToReal(value) {
          return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
        },

        back() {
          this.eventClickToBackGTM();
          if(this.from === 'propostas') {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostas', 'propostas');
            this.updateCustomData();
            this.offerInfo.dueDate = this.offerInfo.dueDateOriginal;
            this.$router.push({
              name: "propostas",
              params: { agreementInfo: this.offerInfo }
            });
          } else if (this.from === 'propostasCdc') {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostasCdc', 'propostasCdc');
            this.updateCustomData();
            this.$router.push({
              name: "propostasCdc",
              params: { agreementInfo: this.agreementInfo, from: 'resumoAcordo', sumTotalInstallmentsCdc: this.sumTotalInstallmentsCdc, totalInstallmentsCdcWithFees: this.totalInstallmentsCdcWithFees }
            });
          }
          else {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostasContencioso', 'propostasContencioso');
            this.updateCustomData();
            this.$router.push({
              name: "propostasContencioso",
              params: { agreementInfo: this.offerInfo }
            });
          }

        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          // const pageName = this.$options.name;
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        eventClickToBackGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:voltar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: this.isPst ? this.buildPayloadOfferChoosePST() : this.buildPayloadOfferChoose(),
            product_identify: this.offerInfo.contract,
            product_category: this.offerInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.offerInfo.product,
            vertical: 'bank',
          };

          console.log("eventData ClickToBack RESUMO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        buildPayloadOfferChoose() {
          var formatedPayload = null;
          if(this.offerInfo.installmentsNumber === 1) {
            formatedPayload = `Valor de desconto:${this.formatFloatToReal(parseFloat(this.offerInfo.value, 10))}:Pagamento à vista de ${this.formatFloatToReal(parseFloat(this.offerInfo.installmentValue, 10))}`;
          } else {
            var value = this.offerInfo.inputValue ? this.formatFloatToReal(parseFloat(this.offerInfo.inputValue, 10)) : this.formatFloatToReal(parseFloat(this.offerInfo.installmentValue, 10));
            formatedPayload = `Valor de desconto:${this.formatFloatToReal(parseFloat(this.offerInfo.value, 10))}: Entrada de ${value} + ${this.offerInfo.installmentsNumber - 1}x ${this.formatFloatToReal(parseFloat(this.offerInfo.installmentValue, 10))}`;
          }
          return truncateString(formatedPayload, 100);
        },

        buildPayloadOfferChoosePST() {
          var formatedPayload = null;
          formatedPayload = `${this.offerInfo.installmentsNumber}x ${this.formatFloatToReal(parseFloat(this.offerInfo.installmentValue, 10))}`;
          
          return truncateString(formatedPayload, 100);
        },

        eventClickConfirmarGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:confirmar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: this.isPst ? this.buildPayloadOfferChoosePST() : this.buildPayloadOfferChoose(),
            product_identify: this.offerInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData ClickConfirmar RESUMO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventCallbackSuccessAgreementGTM(value) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'confirmar:negociacao:sucesso';

          const eventData = {
            event: 'self_service',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'Acordo fechado com sucesso',
            product_identify: this.offerInfo.contract,
            product_category: this.offerInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.offerInfo.product,
            vertical: 'bank',
            service_protocol: value,
            service_detail: 'Acordo fechado com sucesso',
            service_type: 'solicitacao',
          };

          console.log("eventData CallbackSuccessAgreement RESUMO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'self_service', ...eventData });

        },

        eventCallbackErrorAgreementGTM(erro) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'confirmar:negociacao:alert';

          const eventData = {
            event: 'alert',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'Não foi possível fechar o acordo, tente novamente mais tarde!',
            product_identify: this.offerInfo.contract,
            product_category: this.offerInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.offerInfo.product,
            vertical: 'bank',
            alert_code: erro.status,
            error_service: '',
            alert_service_message: erro.message,
            alert_event: 'self_service',
            alert_type: 'erro',
          };

          console.log("eventData CallbackErrorAgreement RESUMO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'self_service', ...eventData });

        },

        updateCustomData() {
          const globals = initializeGlobals();
          window.customData = globals.customData;
        }


    },
    computed: {
      ...mapState("auth", ["user"]),
      ...mapState("minhasDividas", ["agreementResume","contractNegotiable","contractNegotiableOptions"]),
    }

};
</script>
